<template>
  <div class="content-grid">
    <div class="header-top-part">
      <div class="logo-container">
        <a :href="project.url" class="">
          <img :src="project.header_logo" width="136" :alt="logo_alt">
        </a>
      </div>
      <div class="main-nav-container">
        <main-menu></main-menu>
      </div>
      <div class="user-actions-container">
        <a :href="'tel:'+contact_us_phone" class="phone d-lg-none">
          <i class="fa-solid fa-phone me-2"></i>
        </a>
        <a href="javascript:;" role="button" data-bs-toggle="modal" data-bs-target="#helpDrawer">
          <i class="fa-solid fa-messages"></i>
          <span class="text d-none d-lg-block">{{ t('Pagalba') }}</span>
        </a>
      </div>
      <div class="main-menu-burger-container">
        <main-menu-burger></main-menu-burger>
      </div>
    </div>
    <div class="header-bottom-part" v-if="show_search">
      <travel-search :standalone="standalone" :url="products_page.full_url" ref="travelSearch"></travel-search>
    </div>
  </div>


  <mobile-drawer ref="mobileSearchDrawer" :standalone="standalone" :url="products_page.full_url"></mobile-drawer>
  <div id="mainMenuMobDrawer">
    <main-menu-drawer></main-menu-drawer>
  </div>
</template>

<script>
// import Swiper from 'swiper/bundle';


import MainMenuDrawer from "./mainMenuDrawer.vue";
import MainMenuBurger from "./mainMenuBurger.vue";
import MainMenu from "./mainMenu.vue";
import TravelSearch from "./travelSearch.vue";
import MobileDrawer from "./travel/mobileDrawer.vue";

export default {
  components: {MobileDrawer, MainMenu, MainMenuBurger, MainMenuDrawer, TravelSearch},
  props: [],
  data() {
    return {
      standalone: typeof window.standalone !== 'undefined'?window.standalone:1,
      isActive: false,
      backdrop: false,
      show_search: true,
      project: window.project,
      logo_alt: window.logo_alt,
      content_type: window.content_type,
      products_page: window.products_page,
      contact_us_phone: window.contact_us_phone,
      travelSearchC: TravelSearch,
      searchDisabledTypes: ['products/detailed', 'news/listing.tpl', 'news/detailed.tpl', 'pages/contacts', 'pages/editor_detailed', 'pages/not_found.tpl']
    }
  },
  mounted() {
    // console.log(this.content_type);
    if (this.searchDisabledTypes.includes(this.content_type)) {
      this.show_search = false;
    }
  },
  methods: {
  }
}
</script>